
import { Options, Vue } from 'vue-class-component';
import { CompanyClient } from '@/services/Services';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import addLicenseModal from '../modals/addLicenseModal.vue';

@Options({
    components: {}
})
export default class CompanyEdit extends Vue {

    company: OM.Company = null;

    created() {
        this.init();
    }

    init() {
        CompanyClient.getById(this.$route.params.identifier as string)
        .then(x => {
            this.company = x;
            this.company.licenses = this.company.licenses || [];
        })
    }

    addLicense(){
        this.$opModal.show(addLicenseModal, {
            company: this.company
        }, () => {
            this.init();
        })
    }

    removeLicense(item: OM.GarmentLicense){
        if(!confirm(this.$localizationService.getLocalizedValue("Confermi la rimozione?", "Confermi la rimozione?")))
            return;

        CompanyClient.removeLicense(this.$route.params.identifier as string, item.identifier)
        .then(x => this.init())
    }
}
