
import { Prop, Watch } from 'vue-property-decorator';
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { CompanyClient, GarmentClient } from '@/services/Services';

@Options({})
export default class addLicenseModal extends Vue {

    @Prop() company: OM.Company;

    vm: OM.AddGarmentLicenseVM = new OM.AddGarmentLicenseVM();
    garments: OM.TextIdentifier[] = [];

    created() {
        GarmentClient.getAllConfigurations()
        .then(x => this.garments = x)

        this.vm.companyIdentifier = this.company.identifier;
    }

    save() {
        CompanyClient.addLicense(this.vm)
        .then(x => {
            this.$emit('close');
        })
    }

    get disabled(){
        return !this.vm.garmentLicense.simultaneousAccessNumber || this.vm.garmentLicense.simultaneousAccessNumber <= 0 || 
            !this.vm.garmentLicense.expiresOn || !this.vm.garmentLicense.garment.identifier || !this.vm.garmentLicense.garment.text;
    }
    
}
